<template>
  <div class="navbar">
    <img src="@/assets/logo.png" />
    <el-menu
      :default-active="activeMenu"
      class="navbar-menu"
      background-color="#545c64"
      text-color="#fff"
    >
      <el-menu-item
        v-for="(item, index) in routeList"
        :key="index"
        :index="item.name"
        v-show="item.meta.isShow"
        class="navbar-menu-item flex"
        @click="toLink(item.path)"
      >
        <i :class="item.meta.icon"></i>
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routeList: [],
    };
  },
  computed: {
    // 通过路由判断激活
    activeMenu() {
      const route = this.$route;
      let { name } = route;
      return name;
    },
  },
  mounted() {
    this.routeList = this.$router.options.routes[2].children;
  },
  methods: {
    toLink(val) {
      if (this.$route.path == "/admin/" + val) return;
      this.$router.push("/admin/" + val);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 220px;
  height: 100vh;
  background-color: #545c64;
  padding-top: 20px;

  > img {
    width: 136px;
    height: 36px;
    margin-bottom: 20px;
  }

  &-menu {
    width: 100%;
    height: calc(100% - 60px);
    border: none;
    text-align: left;

    .navbar-menu-item {
      align-items: center;
    }
  }
}
</style>
