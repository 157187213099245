<template>
  <div class="admin">
    <HeaderBar />
    <NavBar />
    <div id="content" class="minWidth">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import HeaderBar from "@/components/Header.vue"
export default {
  components: {
    NavBar,
    HeaderBar,
  },
}
</script>

<style></style>
