<template>
  <div class="header minWidth flex">
    <el-dropdown class="header_left flex" @visible-change="dropdownChange">
      <span class="el-dropdown-link">
        欢迎您，{{ name }}
        <i class="el-icon-caret-bottom" :class="{ active: iconFlag }"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item @click.native="toLink">概况</el-dropdown-item> -->
        <el-dropdown-item @click.native="dialogUpdate = true">修改密码</el-dropdown-item>
        <el-dropdown-item @click.native="exit">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="header_right flex">
      <el-button type="primary" @click="build">生成官网</el-button>
      <el-divider direction="vertical"></el-divider>
      <p @click="toMyWeb">浏览网站</p>
      <el-divider direction="vertical"></el-divider>
      <p class="header_right-change" @click="dialogVisible = true">更换模板</p>
    </div>
    <el-dialog title="更换模板" :visible.sync="dialogVisible" width="80%" center>
      <div class="dialog_model-list flex">
        <div
          class="dialog_model-item"
          :class="{ active: modelIndex == item.id }"
          v-for="item in modelList"
          :key="item.id"
        >
          <el-image :src="item.pic" lazy></el-image>
          <div class="dialog_model-item-btn flex">
            <el-button plain size="small">点击预览</el-button
            ><el-button
              type="text"
              class="dialog_model-item-btn-right"
              size="small"
              @click="checkModel(item.id)"
            >
              <i class="el-icon-check"></i> 使用这个模板</el-button
            >
          </div>
        </div>
        <div class="dialog_model-error">
          <span>更多模板敬请期待</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="saveLoad" @click="changeModel">更换模板</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="dialogUpdate" width="30%" center>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="新密码" prop="pass" required>
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
            style="width: 200px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass" required>
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
            style="width: 200px;"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="updateLoading"
          >确 定</el-button
        >
      </span></el-dialog
    >
  </div>
</template>

<script>
import api from "@/api/website.js"
import apiUser from "@/api/service.js"

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能小于6位"))
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass")
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }
    return {
      // 图标状态
      iconFlag: false,
      // 弹窗状态
      dialogVisible: false,
      modelList: [],
      // 按钮状态
      saveLoad: false,
      // 姓名
      name: "",
      dialogUpdate: false,
      // 修改密码
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      updateLoading: false,
    }
  },
  computed: {
    // 选中的模板
    modelIndex: {
      get() {
        return this.$store.getters.modelId
      },
      set(val) {
        this.$store.commit("setModelId", val)
      },
    },
  },
  methods: {
    // 图标状态
    dropdownChange(val) {
      this.iconFlag = val
    },
    // 选中模板
    checkModel(val) {
      this.modelIndex = val
    },
    // 首页
    toLink() {
      this.$router.push("/admin/basics")
    },
    // 更换模板
    async changeModel() {
      this.saveLoad = true
      try {
        const res = await api.saveWebInfo({
          modelId: this.modelIndex,
        })
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "更换成功！",
            duration: 1000,
          })
          this.saveLoad = false
        } else {
          this.$message.error("网络故障！")
          this.saveLoad = false
        }
      } catch (error) {
        this.$message.error(error)
        this.saveLoad = false
      }
    },
    // 修改密码
    submitForm(formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.updateLoading = true
          that.updatePass()
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    // 提交密码
    async updatePass() {
      const that = this
      try {
        let condition = {
          password: this.$md5(this.ruleForm.pass),
          companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
          userId: JSON.parse(localStorage.getItem("userInfo")).userId,
        }
        const res = await apiUser.updateUserInfo(condition)
        if (res.data.code == 200) {
          this.updateLoading = false
          this.dialogUpdate = false
          this.$message({
            message: "修改成功！请重新登录",
            type: "success",
            duration: 2000,
            onClose: function() {
              localStorage.removeItem("userInfo")
              sessionStorage.removeItem("authorization")
              that.$router.push("/login")
            },
          })
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 获取模型列表
    async getModelList() {
      try {
        const res = await api.getModelList()
        if (res.data.code == 200 && res.data.result) {
          this.modelList = res.data.result
        } else {
          this.modelList = []
          this.$message.error("网络故障!")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 生成官网
    async build() {
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        address: JSON.parse(localStorage.getItem("userInfo")).address,
        company: JSON.parse(localStorage.getItem("userInfo")).company,
      }
      try {
        const res = await api.buildTemplate(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "创建官网成功！",
            duration: 1000,
          })
        } else {
          this.$message.error(res.data.message)
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 浏览网站
    toMyWeb() {
      window.open(localStorage.getItem("webUrl"))
    },
    // 登出
    exit() {
      localStorage.removeItem("userInfo")
      sessionStorage.removeItem("authorization")
      const that = this
      this.$message({
        type: "success",
        message: "退出成功！",
        duration: 800,
        onClose: function() {
          that.$router.push("/login")
        },
      })
    },
  },
  mounted() {
    this.name = JSON.parse(localStorage.getItem("userInfo")).company
    this.getModelList()
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: calc(100% - 220px);
  margin-left: 220px;
  height: 60px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  &_left {
    display: flex;
    align-items: center;
  }

  &_right {
    align-items: center;
    > p {
      margin: 0 20px;
      cursor: pointer;
    }

    &-change {
      padding: 5px 20px;
      border-radius: 5px;
      border: 1px solid #ededed;
    }
  }

  .dialog_model-list {
    flex-wrap: wrap;

    .dialog_model-item {
      width: 31%;
      height: 350px;
      margin: calc((100% - 93%) / 6);
      border: 1px solid #ededed;
      transition: all 0.2s;
      border-radius: 5px;
      overflow: hidden;

      .el-image {
        width: 100%;
        height: 300px;
      }

      .dialog_model-item-btn {
        height: 50px;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;

        .el-icon-check {
          display: none;
        }
      }
    }

    .dialog_model-item.active {
      box-shadow: 0px 0px 10px #31b1ff;
      border: none;

      .dialog_model-item-btn {
        background-color: #31b1ff;

        .dialog_model-item-btn-right {
          color: #fff;

          .el-icon-check {
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }

    .dialog_model-error {
      width: 31%;
      height: 350px;
      margin: calc((100% - 93%) / 6);
      border-radius: 5px;
      background-color: #f5f5f5;
      text-align: center;
      line-height: 350px;
    }
  }

  .el-dropdown-link {
    font-size: 14px;
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }

  .el-icon-caret-bottom {
    transition: all 0.2s;
  }

  .el-icon-caret-bottom.active {
    transform: rotate(180deg);
  }
}
</style>
