import request from "@/utils/request.js";

export default {
  // 登录
  login(obj) {
    return request({
      url: "/login",
      method: "post",
      data: {
        username: obj.username,
        password: obj.password,
        role: "3",
      },
    });
  },
  // 注册
  createUser(obj) {
    return request({
      url: "/createUser",
      method: "post",
      data: obj,
    });
  },
  // 修改密码
  updateUserInfo(obj) {
    return request({
      url: "/updateUser",
      method: "post",
      data: obj,
    });
  },
  // 获取操作日志
  getUserLog(obj) {
    return request({
      url: "/getUserLog",
      method: "post",
      data: obj,
    });
  },
};
